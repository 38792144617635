import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';

import { compose } from 'recompose';

import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import IngredientInput from './IngredientInput';
import IngredientSelect from './IngredientSelect';

import {
  translatableName,
  orderByTranslatableName,
} from '../../shared/utils';

const I18n = window.I18n;

const IngredientForm = ({ itemName, actions, expand_form, people, recipe, offered_ingredients = [], user, ...props}) => {
  const [items, setItems] = useState([]);
  const [recipeCategories, setRecipeCategories] = useState((recipe || {}).recipe_categories || []);
  const [newIngedient, setNewIngedient] = useState(""); // prepares the input

  const isOffered = (item) => {
    return itemName === 'order' && !!offered_ingredients.find( e => e.id === (item.id || item.ingredient_id) )
  }

  const itemsUnit = uniq(items.map( e => e.measure ));

  const selectItems = props.ingredients.filter( e => items.map(e => e.name).indexOf(e.name) === -1 );
  const selectItemsUnit = uniq(selectItems.map( e => e.measure ));
  const selectUnit = uniq(Array(itemsUnit, selectItemsUnit).flat()).filter(Boolean);
  const chef = user.chefs.find( i => i.id === user.chef_id ) || {}
  const ar   = chef.ar || []
  const ai   = chef.ai || []

  const formRef = useRef();
  const ingredientNameRef = useRef();
  const ingredientRef = useRef();
  const quantityRef = useRef();
  const measureRef = useRef();

  const onIngredientChange = e => {
    const newValue = e.currentTarget.value;
    let item = selectItems.find( e => e.name == newValue)

    delete item.quantity
    delete item.measure

    if (items.find( i => item.name === i.name )) return

    setItems(prev => [...prev, item])

    ingredientRef.current.value = '';
  }

  const onQuantityChange = e => {
    const newValue = e.currentTarget.value;
    let lastItem = items.slice(-1)[0];

    if (!lastItem) return;
    if (!newValue) return;

    lastItem.quantity = newValue;

    setItems(prev => prev.reduce((previousValue,currentValue) => {
        const accum = previousValue;
        if (currentValue.id && currentValue.id === lastItem.id) {
          accum.push(lastItem);
        } else {
          accum.push(currentValue);
        }
        return accum
      },
      []
    ));

    quantityRef.current.value = '';
  }

  const onMeasureChange = e => {
    const newValue = e.currentTarget.value;
    let item = items.slice(-1)[0];

    if (!item) { return }

    item.measure = newValue;

    setItems(prev => prev.reduce((previousValue,currentValue) => {
        const accum = previousValue;
        if (currentValue.id && currentValue.id === item.id) {
          accum.push(item);
        } else if (currentValue.name && currentValue.name === item.name) {
          accum.push(item);
        } else {
          accum.push(currentValue);
        }
        return accum
      },
      []
    ));

    measureRef.current.value = '';
  }

  const isDisabled = () => {
    const lastItem = items.slice(-1)[0];

    if (!lastItem) return false;
    return ['name', 'quantity', 'measure'].some( e => !lastItem[e] );
  }

  const removeItem = (e, index) => {
    e.preventDefault();

    setItems(prev => prev.filter((_,i) => i !== index))
  }

  const updateNewIngredient = target => {
    setNewIngedient(target.value)
  }

  const appendNewIgredient = () => {
    const item  = {name: newIngedient};

    if (!newIngedient) return
    if (items.find( i => item.name === i.name )) return

    setItems(prev => [...prev, item]);

    ingredientNameRef.current.value = '';
  }

  const QuantityInput = ({ item }) =>
    expand_form ?
      <li className='quantity'>
        <input
          type="number"
          name={`${itemName}[ingredients][quantity][]`}
          defaultValue={parseFloat(item.quantity).toFixed(1)}
          autoComplete="off"
          hidden={false}
        />
      </li> :
      <li className='quantity'>
        {item.quantity}
        <input type="text" name={`${itemName}[ingredients][quantity][]`} defaultValue={parseFloat(item.quantity).toFixed(1)} autoComplete="off" hidden={true}/>
       </li>

  QuantityInput.propTypes = {
    item: PropTypes.shape({
      quantity: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  }
  // Duplicate MealsView
  const isAvailableIngredient = (id) =>
    !!ai.find( i => i.id === id )

  const MeasureOptions = ({ item, index }) =>
    expand_form ?
      <li className='measure'>
        <select name={`${itemName}[ingredients][measure][]`} defaultValue={item.measure}>
          <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
          <QuantityMeasureOptions />
        </select>
        <button
          onClick={ e => removeItem(e,index) }
          className='material-icons'
          style={isAvailableIngredient(item.ingredient_id) ? {color: 'green'} : {}}
         >
           cancel
        </button>
      </li> :
      <li className='measure'>
        {item.measure && I18n.t(item.measure, {scope: 'quantity'})}
        <input type="text" name={`${itemName}[ingredients][measure][]`} defaultValue={item.measure} autoComplete="off" hidden={true}/>
        <button
          onClick={ e => removeItem(e, index) }
          className='material-icons'
          style={isAvailableIngredient(item.ingredient_id) ? {color: 'green'} : {}}
         >
           cancel
        </button>
      </li>

  MeasureOptions.propTypes = {
    item: PropTypes.shape({
       measure: PropTypes.string
    }),
    index: PropTypes.number
  }

  const QuantityMeasureOptions = () => {
    const excludeCook    = ['order', 'stock']
    const quantity       = (I18n.translations && I18n.translations.en && I18n.translations.en.quantity) || [];
    const options        = Object.keys(quantity)
    const measureOptions = excludeCook.includes(itemName) ? options.filter( e => e !== 'cook') : options

    return measureOptions.map( (type, i) => {
      return(
        <optgroup label={I18n.t(type, {scope: 'views.quantity'})} key={i}>
          { Object.keys(quantity[type]).map( e => {
            const value = `${type}.${e}`
            return <option key={`opt${e}`} value={value}>{I18n.t(value, {scope: 'quantity'})}</option>
          })}
        </optgroup>
      )
    })
  }

  const PeopleSelect = () => {
    const peopleRef = useRef();
    const peopleSelectRef = useRef();

    const onPeopleChange = e => {
      const newValue = e.currentTarget.value;
      peopleRef.current.value = newValue;
      peopleSelectRef.current.value = newValue;
    }

    switch(itemName) {
      case 'recipe':
        return(
          <React.Fragment>
            <li className='quantity'>
              {I18n.t('views.people', {count: people || 2})}
              <input name='recipe[people]' ref={peopleRef} defaultValue={people} hidden/>
            </li>
            <li className='measure'>
              <select onChange={onPeopleChange} defaultValue={people} ref={peopleSelectRef}>
                <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                { [1,2,3,4,5,6,7].map( e => <option key={`peopl${e}`} value={e}>{e.toLocaleString(I18n.currentLocale())}</option> ) }
              </select>
            </li>
          </React.Fragment>
        )
      default:
        return(
          <React.Fragment>
            <li className='quantity'></li>
            <li className='measure'></li>
          </React.Fragment>
        )
    }
  }

  const RecipeCategorySelect = () => {
    const categoryNameRef = useRef();
    const categorySelectRef = useRef();

    const onNewCategoryBlur = e => {
      const value    = e.currentTarget.value

      setRecipeCategories( previousValue => [...(previousValue||[]), { id: 'null', name: value }])
    }

    const onCategoryChange = e => {
      const value = e.currentTarget.value
      const item  = props.recipes_categories.find( e => e.id === parseInt(value))

      if ((recipeCategories || []).find( e => e.id === item.id ) ) return

      setRecipeCategories( previousValue => [...(previousValue||[]), item])
    }

    const RecipeCategories = ({ category }) =>
      <ul>
        <li className='name'>
          {translatableName(category)}
          <input name='recipe[recipe_categories][name][]' defaultValue={category.name} hidden/>
        </li>
        <li className='quantity'>
        </li>
        <li className='measure'>
        </li>
      </ul>

    RecipeCategories.propTypes = {
      category: PropTypes.shape({
        name: PropTypes.string
      }),
      recipes_categories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        })
      )
    }

    switch(itemName) {
      case 'recipe':
        return(
          <>
            <ul className='header'>
              <li>{I18n.t('views.cards.forms.recipe.recipe_categories')}</li>
            </ul>
            {
              (recipeCategories||[]).map( (e,i) =>
                <RecipeCategories key={`c_${i}_${e.id}`} category={e}/>
              )
            }
            <ul>
              <li className='name'>
                <input type='text'
                  onBlur={onNewCategoryBlur}
                  ref={categoryNameRef}
                  name=''
                  autoComplete="off"
                />
              </li>
              <li className='quantity'></li>
              <li className='measure'></li>
            </ul>
            <ul>
              <li className='name'>
                <select onChange={onCategoryChange} ref={categorySelectRef}>
                  <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
                  {
                    orderByTranslatableName(props.recipes_categories).map( e =>
                      <option key={`cat_${e.id}`} value={e.id}>{translatableName(e)}</option>
                    )
                  }
                </select>
              </li>
              <li className='quantity'></li>
              <li className='measure'></li>
            </ul>
          </>
        )
     default:
      return null;
    }
  }

  useEffect( () => {
    actions.setIngredientFormElement({[itemName]: formRef.current})
  }, [])

  useEffect( () => {
    setRecipeCategories(recipe.recipe_categories)
  }, [recipe])

  useEffect( () => {
    setItems( prev => props.items.reduce( (previousValue, currentValue) => {
      const accum = previousValue;

      if (accum.find( e => e.name === currentValue.name) ) {
        return accum
      } else {
        accum.push(currentValue)
      }

      return accum
    }, prev))
  }, [props.items.length])

  return(
    <Lifecycle
      onMount={ ()=> {
        if (itemName === 'recipe') {
          actions.fetchRecipesCategories()
        }
      }}
    >
      <form ref={formRef} action="">
        {props.children}
        <ul className='header'>
          <li className='name'>{I18n.t('views.cards.forms.ingredient.ingredient')}</li>
          <li className='quantity'>{I18n.t('views.cards.forms.ingredient.quantity')}</li>
          <li className='measure'>{I18n.t('views.cards.forms.ingredient.unit')}</li>
        </ul>
        {
          items.map( (i,index) =>
            <ul key={`${i.id}_${index}`}>
              <IngredientInput 
                itemName={itemName} 
                item={i} 
                expand_form={expand_form} 
                ingredients={props.ingredients}
                isOffered={isOffered}
              />
              <QuantityInput item={i} />
              <MeasureOptions item={i} index={index} />
            </ul>
          )
        }
        <ul>
          <li className='name'>
            <input type='text'
              onChange={ e => updateNewIngredient(e.target) }
              onBlur={ e => appendNewIgredient(e.target) }
              ref={ingredientNameRef}
              name=''
              autoComplete="off"
            />
          </li>
          <li className='quantity'>
            <input
              ref={quantityRef}
              onBlur={onQuantityChange}
              type='number'
              name=''
              autoComplete="off"
            />
          </li>
          <li className='measure'>
            <select onChange={onMeasureChange} ref={measureRef}>
              <option key='null' value='' defaultValue=''>{I18n.t('views.cards.actions.quick_select')}</option>
              <QuantityMeasureOptions />
            </select>
          </li>
        </ul>
        <ul>
          <li className='name'>
            <IngredientSelect
              selectItems={selectItems}
              onIngredientChange={onIngredientChange}
              ingredientRef={ingredientRef}
              isDisabled={isDisabled}
            />
          </li>
          <PeopleSelect />
        </ul>
        <RecipeCategorySelect />
      </form>
    </Lifecycle>
  )
}

IngredientForm.defaultProps = {
  items: [],
  ingredients: [],
  recipe: {},
  expand_form: false,
}

IngredientForm.propTypes = {
  user: PropTypes.shape({
    admin: PropTypes.boolean,
    chef_id: PropTypes.number,
    chefs: PropTypes.arrayOf(PropTypes.shape({
      ar: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number
        })
      ),
      ai: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number
        })
      ),
    })
  ),
  }),
  children: PropTypes.any,
  itemName: PropTypes.string,
  recipe: PropTypes.object,
  selectItems: PropTypes.array,
  items: PropTypes.array,
  ingredients: PropTypes.array,
  offered_ingredients: PropTypes.array,
  recipes_categories: PropTypes.array,
  actions: PropTypes.shape({
    fetchRecipesCategories: PropTypes.func,
    setIngredientFormElement: PropTypes.func
  }),
  expand_form: PropTypes.bool,
  people: PropTypes.number
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(IngredientForm);
