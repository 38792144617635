import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectModule } from 'redux-modules';
import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';
import CardWrapper from '../../components/Navigation/CardWrapper';

import { IngredientForm } from '../../components/Ingredients';

import { parseDate, orderByName } from '../../shared/utils';

const I18n = window.I18n;

const StocksNew = ({ user, match, history, ...props }) => {
  const chef   = user && user.chefs && user.chefs.find( e => e.id === parseInt(match.params.chef_id) ) || { ai: [] };
  const items  = (chef.ai || [])
  return(
    <CardWrapper title={I18n.t('views.cards.stock')} className={'recipes'} match={match} history={history}>
      <IngredientForm
        itemName='stock'
        selectItems={props.ingredients}
        items={orderByName(items)}
        expand_form={!!chef.id}
      >
        <input type="text" name='stock[chef_id]' defaultValue={user.chef_id} autoComplete="off" hidden={true}/>
        <div className='fieldset'>
          <label htmlFor='stock-date'>{I18n.t('views.generic.date')}</label>
          <div className='stock-date-type'>
            <input type='date' name='stock[date]' autoComplete="off"
              defaultValue={
                parseDate()
              }
            />
          </div>
        </div>
      </IngredientForm>
    </CardWrapper>
  );
}

StocksNew.propTypes = {
  ingredients: PropTypes.array,
  user: PropTypes.shape({
    chef_id: PropTypes.any,
    chefs: PropTypes.array
  }),
  match: PropTypes.object,
  history: PropTypes.object.isRequired
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(StocksNew);
