import React, { useState } from 'react';
import cn from 'classnames'
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import NotificationWebSocket from './NotificationWebSocket';

import { CableApp } from '../../shared/utils';
import { removeNotification } from '../../services/apiService'

const I18n = window.I18n;

const Notification = ({user: { chef_id, chefs, notifications }, actions, history, types, basket}) => {
  const chef_user = (chef_id && chefs.find( e => e.id === chef_id ) || {});
  const [ visible, setVisible ] = useState(false);
  const active_notifications = (notifications||[]).filter( note => types.includes(note.resource_type) );

  const updateApp = props => console.log(props)

  const isHidden = notifications => notifications.length === 0

  const onNotificationHeaderClick = () => setVisible(!visible)

  // window.notifications = notifications
  // console.log(notifications)

  const onNotificationLabelCheck = (actions, chef_user, notification) => {
    const { resource_type, refs } = notification;

    const joinPrivateRoom = (actions, chef_name, roomname) => {
      actions.joinRoom({username: chef_name, roomname: roomname})
    }

    switch(resource_type) {
      case 'Chat::Room':
        new Promise( resolve => resolve(history.push('/chatMainRoom') ) )
          .then( () => joinPrivateRoom(actions, chef_user.name, refs['room']) )
          .then( () => onNotificationHeaderClick() )
        break;
      case 'Food::Ingredient':
        actions.addOrderNotificationItemToBasket({ stock: refs })
          .then( () => console.log(basket) )
        break;
    }
  }

  const onNotificationLabelCancel = (notification) => {

    removeNotification({
      notification: notification
    })
  }

  const renderTitle = ({ active_notifications }) => {
    if ( active_notifications.length === 0 ) { return '' }

    const multi = new Set(notifications.map( e => e.resource_type )).size > 1

    const getTitle = (notification) => {
      switch(notification.resource_type) {
        case 'Chat::Room':
          return I18n.t('views.cards.notifications')
        case 'Food::Ingredient':
          return I18n.t('views.cards.ingredients')
      }
    }


    return multi ? I18n.t('views.cards.notifications') : getTitle(active_notifications[0])
  }

  return (
    <div id='notification' className={ cn({hidden: isHidden(active_notifications), visible})}>
      <div id="notification-header" className={cn({visible})} onClick={onNotificationHeaderClick}>
        {renderTitle({ active_notifications })} <span>{active_notifications.length}</span>
      </div>
      <div id='notifications' className={ cn({visible}) }>
        {active_notifications.map( (notification, i) => {
          return(
            <ul key={i} className='notify'>
              <li>{notification.sender_name}:</li>
              <li>{notification.content}</li>
              <li>
                <button
                  onClick={ e => onNotificationLabelCheck(actions, chef_user, notification) }
                  className='material-icons'
                >
                  check
                </button>
              </li>
              <li>
                <button
                  onClick={ e => onNotificationLabelCancel(notification) }
                  className='material-icons'
                >
                  cancel
                </button>
              </li>
            </ul>
          )
        })}
      </div>
      <NotificationWebSocket
        cableApp={CableApp}
        updateApp={ props => updateApp(props) }
        chef_id={chef_id}
      />
    </div>
  )
};

Notification.defaultProps = {
  types: ['Chat::Room']
}

Notification.propTypes = {
  types: PropTypes.array,
  actions: PropTypes.shape({
    fetchAI: PropTypes.func,
    recieveNotifications: PropTypes.func,
    joinRoom: PropTypes.func,
    addOrderNotificationItemToBasket: PropTypes.func,
  }),
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    chefs: PropTypes.array,
    notifications: PropTypes.array
  }),
  basket: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Notification);
