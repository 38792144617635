import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { connectModule } from 'redux-modules';
import { compose } from 'recompose';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import CardWrapper from '../../components/Navigation/CardWrapper';
import IngredientSelect from '../../components/Ingredients/IngredientSelect';
import { ROOT_URL } from '../../shared/config';

import { filterByPresentDay } from '../../shared/utils';
import { translatableWithIngredientIdName } from '../../shared/utils';


import uniqBy from 'lodash/uniqBy'

const I18n = window.I18n;

const OrdersList = ({orders, basket, ingredients, user, match, history, actions}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';
  const canView = (((user.can.food || {}).orders) || []);
  const hasShop =   (user.shop_ids || []).length > 0

  const outgoing = orders.filter( i => user.chef_id == i.chef_id || canView.indexOf(i.chef_id) > -1 )
  const incoming = orders.filter( i => user.shop_ids.indexOf(i.shop_id) > -1 )

  const chef = user.chefs.find( i => i.id === user.chef_id ) || {}
  const ai   = chef.ai || []

  const isAvailableIngredient = id => !!ai.find( i => i.id === id )
  console.log(chef)
  const selectItems = ingredients.filter( e => (basket.stocks || []).map(e => e.name).indexOf(e.name) === -1 );
  const ingredientRef = useRef();
  const onIngredientChange = e => {
    const newValue = e.currentTarget.value;
    const chef = user.chefs[0]
    let item = selectItems.find( e => e.name == newValue)

    item.ingredient_id = item.id
    item.quantity = 1

    delete item.categories
    delete item.category_id
    delete item.id

    if (basket.stocks.find( i => item.name === i.name )) return

    console.log(chef)

    actions.addIngredientItemsToBasket({ stocks: [item] })
      .then( () => actions.updateChefBasket({ chef, basket}) )
      
      ingredientRef.current.value = '';
  }
  const onIngredientRemove = item => {
    const chef = user.chefs[0]

    actions.removeIngredientItemFromBasket(item)
      .then( () => actions.updateChefBasket({ chef, basket}) )
  }

  const RenderOrders = ({ result }) =>
    uniqBy(result, 'id').map( (e,i) =>
      <a onClick={ () => history.push(`${root}/orders/${e.id}`, { whatever: true }) } className='item' key={`order_${i}`}>
        <span>{e.date}</span>
        <span>{(e.shop || { name: ''}).name}</span>
      </a>
    )

  const RenderSummary = ({type, result}) =>
    hasShop ?
      <>
        <h3>{I18n.t(type,{scope: 'views.cards.sections.order'})}</h3>
        <details open>
          <summary>{I18n.t('views.cards.reports.orders.today')}</summary>
          <RenderOrders result={filterByPresentDay(result, 'today')} />
        </details>
        <details open>
          <summary>{I18n.t('views.cards.reports.orders.future')}</summary>
          <RenderOrders result={filterByPresentDay(result, 'future')} />
        </details>
        <details>
          <summary>{I18n.t('views.cards.reports.orders.past')} </summary>
          <RenderOrders result={filterByPresentDay(result, 'past')} />
        </details>
      </> :
      type === 'incoming' ?
        null :
        <>
          { hasShop ? <h3>{I18n.t(type,{scope: 'views.cards.sections.order'})}</h3> : null}
          <details open>
            <summary>{I18n.t('views.cards.reports.orders.today')}</summary>
            <RenderOrders result={filterByPresentDay(result, 'today')} />
          </details>
          <details open>
            <summary>{I18n.t('views.cards.reports.orders.future')}</summary>
            <RenderOrders result={filterByPresentDay(result, 'future')} />
          </details>
          <details>
            <summary>{I18n.t('views.cards.reports.orders.past')} </summary>
            <RenderOrders result={filterByPresentDay(result, 'past')} />
          </details>
        </>

  const OrdersBasket = ({basket_items}) =>
    basket_items.map( (e,index) => /* onClick={ () => console.log('clicked') }  */
      <a className='item' key={`ingr_${e.id}_${index}`}>
        <span className={cn({green: isAvailableIngredient(e.ingredient_id)})}>
          {translatableWithIngredientIdName(e, ingredients)}
        </span>
        <span>
          {`${e.quantity} ${I18n.t(e.measure, {scope: 'quantity'})}`}
          <button onClick={ () => { onIngredientRemove(e) } }
            className='material-icons'
          >
            cancel
          </button>
        </span>
      </a>
    )

  RenderSummary.propTypes = {
    result: PropTypes.array,
    type: PropTypes.string,
  }

  return(
    <CardWrapper title={I18n.t('views.cards.orders')} className='orders' match={match} history={history}>
      <RenderSummary result={incoming} type={'incoming'}/>
      <RenderSummary result={outgoing} type={'outgoing'}/>
      <h3>{I18n.t('views.cards.sections.order.basket')}</h3>
      <OrdersBasket basket_items={basket.stocks || []}/>
      <IngredientSelect
        selectItems={selectItems}
        ingredientRef={ingredientRef}
        onIngredientChange={onIngredientChange}
      />
    </CardWrapper>
  )
}

OrdersList.propTypes = {
  ingredients: PropTypes.array,
  actions: PropTypes.shape({
    fetchOrders: PropTypes.func,
    fetchIngredients: PropTypes.func,
    addIngredientItemsToBasket: PropTypes.func,
    removeIngredientItemFromBasket: PropTypes.func,
    updateChefBasket: PropTypes.func
  }),
  basket: PropTypes.shape({
    orders: PropTypes.arrayOf(PropTypes.shape({
        ingredient_id: PropTypes.number,
      })
    ),
    stocks: PropTypes.arrayOf(PropTypes.shape({
        ingredient_id: PropTypes.number,
      })
    )
  }),
  orders: PropTypes.array,
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    chefs: PropTypes.array,
    shop_ids: PropTypes.array,
    can: PropTypes.shape({
      food: PropTypes.shape({
        orders: PropTypes.array
      })
    })
  }),
  match: PropTypes.object,
  history: PropTypes.object,
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(OrdersList);
