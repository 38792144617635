import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Lifecycle from '../../decorators/Lifecycle';
import groupBy from 'lodash/groupBy';

import { orderByName } from '../../shared/utils';
import { DashboardSubHeader } from '../../shared/components';
import { postNotification } from '../../services/apiService';

const I18n = window.I18n;

const AvailableRecipes = ({ fetchAR, chef, match: { path }, history, ingredients, routeState }) => {
  const recipes = chef.ar ? orderByName(chef.ar) : [];
  const selectRef = useRef();
  const onChange = e => {
    history.push(e.currentTarget.value)
  }

  const onRecipeClick = (_, id) => routeState.history.push(`/recipes/${id}`)
  const onIngredientClick = (_, id) => {
    postNotification({
      notification: { chef_id: chef.id, ingredient_id: id }
    })
  }

  const IngredientList = ({recipe, getIngredient}) =>
    <React.Fragment>
      { 
        recipe.missing_ingredients.slice(0,recipe.missing).map( (ingr, index) => 
          <a key={index} className='item' onClick={(e) => onIngredientClick(e, ingr.id)}>
            <span className='level1'>{getIngredient(ingr).name}</span>
          </a>
        )
      }
    </React.Fragment>

  IngredientList.propTypes = {
    recipe: PropTypes.object,
    getIngredient: PropTypes.func,
  }

  const RecipesList = ({recipes, ingredients}) => {
    const getIngredient = ingr => { return(ingredients.find( i => i.id === ingr.id )||{})};
    return(
      <React.Fragment>
        {
          recipes.map( recipe =>
            <React.Fragment key={`recipe_${recipe.id}`}>
              <a className='item' onClick={e => onRecipeClick(e, recipe.id)}>
                {recipe.name}
              </a>
              <IngredientList recipe={recipe} getIngredient={getIngredient} />
            </React.Fragment>
          )
        }
      </React.Fragment>
    )
  }

  RecipesList.propTypes = {
    recipes: PropTypes.array,
    ingredients: PropTypes.array,
  }

  const groupedRecipes = groupBy(recipes, 'missing');
  const groups = Object.keys(groupedRecipes)||[];

  const Group = ({group, groupedRecipes, ingredients}) => 
      <React.Fragment>
        { group === '0' ? '' : <h3>{I18n.t('views.generic.item_missing', {count: parseInt(group), item: group })}</h3>}
        <RecipesList
          recipes={groupedRecipes[group]}
          ingredients={ingredients}
        />
      </React.Fragment>

  Group.propTypes = {
    group: PropTypes.string,
    groupedRecipes: PropTypes.object,
    ingredients: PropTypes.array
  }

  return(
    <Lifecycle
      onMount={ () => {
        fetchAR()
      }}
    >
      <DashboardSubHeader
        title={I18n.t('views.reports.available_recipes')}
        onChange={onChange}
        selectRef={selectRef}
        path={path}
      />
      {
        groups.map( (group,i) =>
          <Group
            key={`group_${i}`}
            group={group}
            groupedRecipes={groupedRecipes}
            ingredients={ingredients}
          />
        )
      }
    </Lifecycle>
  )
}

AvailableRecipes.propTypes = {
  fetchAR: PropTypes.func,
  chef: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    ar: PropTypes.any
  }),
  match: PropTypes.shape({
    path: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  ingredients: PropTypes.array,
  routeState: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func
    }),
  }),
}

export default AvailableRecipes;
