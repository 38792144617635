import React from 'react';
import actionCable from 'actioncable';
import uniqBy from 'lodash/uniqBy';

const I18n = window.I18n;

const WebSocketURL = process.env.RAILS_ENV == 'production' ?
  'wss://chefy.gr/cable' :
  'ws://localhost:9090/cable'

export const CableApp = {
  cable: actionCable.createConsumer(WebSocketURL)
}

export const initBasket = () => JSON.parse(localStorage.getItem('basket') || '{"orders":[],"stocks":[]}' )
export const setBasket = basket => localStorage.setItem('basket', JSON.stringify(basket))
export const getBasket = () => initBasket()
export const loadBasket = user => {
  return Object.entries(getBasket())
    .reduce((acc, [k, v]) => 
      Object.assign(acc, { [k]: uniqBy(v.concat(...user.chefs.flatMap(e => (e.basket||{})[k] || [])), 'ingredient_id') } ),{})
}

export const formatContent = (content) => {
  switch(true) {
    case /http/.test(content):
      return <a href={content} target='_blank' rel="noopener noreferrer">{content}</a>
    default:
      return content
  }
}

export const formatNumber = (number) => {
  return parseFloat(number).toLocaleString(window.I18n.locale, {minimumFractionDigits: 1, maximumFractionDigits: 1})
}
export const orderBy = array =>
  (array || []).concat().sort( (a,b) => a.localeCompare(b) )

export const orderByName = array =>
  (array || []).concat().sort( (a,b) => a.name.localeCompare(b.name) );

export const orderByTranslatableName = array =>
  (array || []).concat().sort( (a,b) => translatableName(a).localeCompare(translatableName(b)) );

export const orderByDate = array =>
  (array || []).concat().sort( (a,b) => a.date.localeCompare(b.date) );

export const formatDateISO = date =>
  date ? new Date(date).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]

// navigator.languages.slice(-1)[0]
export const formatDate = (date, options = {}) =>
  new Intl
        .DateTimeFormat(window.I18n.locale, options)
        .format(
          date ? new Date(date) : new Date() // 2020-08-31
        )

export const formatDateLong = date =>
  formatDate(date, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })

const formatWeekday = date =>
  formatDate(date, { weekday: 'long' })

const mondayBased = (day, offset) => {
  const msPerDay = 24 * 60 * 60 * 1000;
  const date = new Date(day);
  let result;

  if (offset === 0) {
    result = (date - (6 * msPerDay))

    return result
  } else {
    result = (date - ((offset - 1) * msPerDay))

    return result
  }
}

export const formatMondayBaseWeekday = (day, offset) =>
  formatWeekday(mondayBased(day, offset))

export const sundayBasedWeekday = period =>
  period === 0 ? period + 1 : period === 6 ? 0 : period + 1

export const parseDate = date =>
  date || new Date().toISOString().split('T')[0]

export const maskId = myemailId => (myemailId || '').replace(/^(.)(.*)(.@.*)$/,
     (_, a, b, c) => a + b.replace(/./g, '*') + c
  );

export const filterByPresentDay = (array, period) => {
  let result
  switch(period) {
    case 'past':
      result = array.filter( e => formatDateISO() > formatDateISO(e.date)  )
      result = orderByDate(result).reverse()
      break;
    case 'future':
      result = array.filter( e => formatDateISO() < formatDateISO(e.date)  )
      result = orderByDate(result)
      break;
    case 'today':
      result = array.filter( e => formatDateISO() === formatDateISO(e.date)  )
      break;
  }

  return result
}

export const translatableName = ingredient =>
  ingredient.locale === I18n.locale ?
    ingredient.name :
      ingredient['t'] && ingredient['t'][I18n.locale] ?
        ingredient.t[I18n.locale] :
          ingredient.name

export const translatableWithIngredientIdName = (translatable, ingredients) =>
  translatableName( ingredients.find( e => e.id === translatable.ingredient_id) || translatable )

export const translatableWithDietableIdName = (translatable, collection) =>
  translatableName( collection.find( e => e.id === translatable.dietable_id) || translatable )

export const translatableWithIdName = (translatable, collection) =>
  translatableName( collection.find( e => e.id === translatable.id) || translatable )

export const fetchIfEmpty = (collection, action) =>
  collection.length === 0 && action.call()

export const translatableAttribute = (recipe, attribute) =>
  recipe.locale === I18n.locale ?
    recipe[attribute] :
      recipe[`${attribute}_t`] && recipe[`${attribute}_t`][I18n.locale] ?
        recipe[`${attribute}_t`][I18n.locale] :
          recipe[attribute]

export const translatableAttributeWithFallback = (recipe, attribute, fallbackAttr) =>
  recipe.locale === I18n.locale && recipe[attribute] ?
    recipe[attribute] :
      recipe[`${attribute}_t`] && recipe[`${attribute}_t`][I18n.locale] ?
        recipe[`${attribute}_t`][I18n.locale] :
          translatableAttribute(recipe, fallbackAttr)

export const queryParams = () =>
  Object.fromEntries(new URLSearchParams(window.location.search).entries());
