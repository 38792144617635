import { createModule } from 'redux-modules';
import { liftState, Cmd, loop } from 'redux-loop';
import { fromJS, update } from 'immutable';
import {
  getJson,
  postJson,
  postBodyJson,
  putBodyJson,
  deleteBodyJson
} from '../../services/apiService';

import { APP_URLS } from '../../shared/config'
import { initBasket, loadBasket, setBasket } from '../../shared/utils';

const initialState = {
  user: {
    can: {},
    allow: {},
    chefs: [],
    contacts: {
      food: [],
      food_contacts: [],
      friend_requests: {},
    },
    rooms: [],
    rooms_user: { id: '' },
    stats: {
      online: 0,
      chatting: 0
    },
    notifications: [],
    reports: {
      active_diets: {
        periods: [],
        seasons: [],
        days: [],
        offset: 0,
        meal_periods: [],
        dietable_types: [],
        open_items: []
      },
      recipes: {
        open_items: []
      }
    },
    info: {}
  },
  urls: {},
  basket: initBasket(),
  meals: [],
  members: [],
  modal: {
    open: false,
    modalType: null,
    modalActions: {},
    current: null,
    goBack: null,
    history: null
  },
  chef_guests: [],
  recipes: [],
  recipes_categories: [],
  ingredients: [],
  categories: [],
  shops: [],
  orders: [],
  stocks: [],
  diets: [],
  elements: {
    mealsFormRef: {},
    ingredientFormRef: {},
    permissionFormRef: {},
    categoryFormRef: {},
    dietFormRef: {},
    chefGuestFormRef: {},
    recipesCategoryFormRef: {},
    shopFormRef: {},
    scheduleFormRef: {},
    userInfoFormRef: {},
  },
  loading: false,
  fetched: false
}

const meals = createModule({
  name: 'meals',
  initialState,
  selector: state => ({
    user: state.user||initialState.user,
    urls: state.urls||{},
    basket: state.basket||initialState.basket,
    meals: state.meals||[],
    members: state.members||[],
    modal: state.modal||initialState.modal,
    chef_guests: state.chef_guests||[],
    recipes: state.recipes||[],
    recipes_categories: state.recipes_categories||[],
    ingredients: state.ingredients||[],
    categories: state.categories||[],
    shops: state.shops||[],
    orders: state.orders||[],
    stocks: state.stocks||[],
    diets: state.diets||[],
    elements: state.elements||initialState.elements,
    loading: state.loading||false,
    fetched: state.fetched||false
  }),
  composes: [liftState],
  transformations: {
    init: () =>  initialState,
    initApp: (state, {
      payload
    }) => ({
      ...state,
      ...payload,
      basket: loadBasket(payload.user)
    }),
    fetchTranslations: (state) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: ['/food/translations'],
          successActionCreator: meals.actions.fetchTranslationsSuccess,
        })
      )
    },
    fetchTranslationsSuccess: (state,action) => {
      window.I18n.translations = action.payload;
      return state
    },
    fetchReports: (state) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: ['/food/reports'],
          successActionCreator: meals.actions.fetchReportsSuccess,
        })
      )
    },
    fetchReportsSuccess: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        reports: {
          ...state.user.reports,
          active_diets: {
            ...state.user.reports.active_diets,
            days: action.payload.reports.active_diets.days
          }
        },
      },
      loading: false,
      fetched: true,
    }),
    createChef: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postBodyJson, {
          args: [APP_URLS['createChef'], action.payload],
          successActionCrbeator: meals.actions.postSuccessChef,
        })
      )
    },
    postSuccessChef: (state, action) => ({
      ...state,
      user: action.payload,
      loading: false,
      fetched: true,
    }),
    fetchAI: {
      reducer: (state) => {
        return(
          loop(
            { ...state, loading: true },
            Cmd.run(getJson, {
              args: [APP_URLS['fetchAI']],
              successActionCreator: meals.actions.fetchAISuccess,
            })
          )
        )
      }
    },
    fetchAISuccess: (state, action) => {
      return({
        ...state,
        user: {
          ...state.user,
          chefs: state.user.chefs.reduce((previousValue,currentValue) => {
              const accum = previousValue;
              if (currentValue.id === action.payload.id) {
                accum.push({
                  ...currentValue,
                  ai: action.payload.ai
                });
              } else {
                accum.push(currentValue);
              }
              return accum
            },
            []
          )
        },
        loading: false,
        fetched: true,
      })
    },
    fetchUI: {
      reducer: (state) => {
        return(
          loop(
            { ...state, loading: true },
            Cmd.run(getJson, {
              args: [APP_URLS['fetchUI']],
              successActionCreator: meals.actions.fetchUISuccess,
            })
          )
        )
      }
    },
    fetchUISuccess: (state, action) => {
      return({
        ...state,
        user: {
          ...state.user,
          chefs: state.user.chefs.reduce((previousValue,currentValue) => {
              const accum = previousValue;
              if (currentValue.id === action.payload.id) {
                accum.push({
                  ...currentValue,
                  ui: action.payload.ui
                });
              } else {
                accum.push(currentValue);
              }
              return accum
            },
            []
          )
        },
        loading: false,
        fetched: true,
      })
    },
    fetchAR: {
      reducer: (state) => {
        return(
          loop(
            { ...state, loading: true },
            Cmd.run(getJson, {
              args: [APP_URLS['fetchAR']],
              successActionCreator: meals.actions.fetchARSuccess,
            })
          )
        )
      }
    },
    fetchARSuccess: (state, action) => {
      return({
        ...state,
        user: {
          ...state.user,
          chefs: state.user.chefs.reduce((previousValue,currentValue) => {
              const accum = previousValue;
              if (currentValue.id === action.payload.id) {
                accum.push({
                  ...currentValue,
                  ar: action.payload.ar
                });
              } else {
                accum.push(currentValue);
              }
              return accum
            },
            []
          )
        },
        loading: false,
        fetched: true,
      })
    },
    fetchFoodContacts: {
      reducer: (state) => {
        return(
          loop(
            { ...state, loading: true },
            Cmd.run(getJson, {
              args: [APP_URLS['fetchFoodContacts']],
              successActionCreator: meals.actions.fetchFoodContactsSuccess,
            })
          )
        )
      }
    },
    fetchFoodContactsSuccess: (state, action) => {
      const user = {
        ...state.user,
        contacts: {
          ...state.user.contacts,
          food_contacts: action.payload
        }
      }
      return({
        ...state,
        user,
        loading: false,
        fetched: true,
      })
    },
    updatePermissions: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updatePermissions']}/${action.payload.share.owner_id}`, action.payload],
          successActionCreator: meals.actions.updatePermissionsSuccess,
        })
      )
    },
    updatePermissionsSuccess: (state, action) => {
      const user = {
        ...state.user,
        ...action.payload
      }
      return({
        ...state,
        user,
        loading: false,
        fetched: true,
      })
    },
    updateUserInfo: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateUserInfo']}`, action.payload],
          successActionCreator: meals.actions.updateUserInfoSuccess,
        })
      )
    },
    updateUserInfoSuccess: (state, action) => {
      const user = {
        ...state.user,
        info: action.payload.info
      }
      return({
        ...state,
        user,
        loading: false,
        fetched: true,
      })
    },
    fetchMeals: state => {
      return(
        loop(
          { ...state, loading: true },
          Cmd.run(getJson, {
            args: [APP_URLS['fetchMeals']],
            successActionCreator: meals.actions.fetchSuccessMeals,
          })
        )
      )
    },
    createMeals: {
      middleware: [ a => { console.log(a); return a }],
      reducer: (state, { payload }) =>
        state.update('collection', todos => todos.push(fromJS(payload))),
    },
    destroyMeals: {
      middleware: [ a => { console.log(a); return a }],
      reducer: (state, { payload }) =>
        state.update('collection', todos => todos.delete(payload)),
    },
    fetchSuccessMeals: (state, action) => ({
      ...state,
      meals: action.payload.meals.reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( d => d.id === currentValue.id)) {
            return accum.map( element => element ) // keep the previous value (most updated)
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.meals
      ),
      loading: false,
      fetched: true,
    }),
    fetchMeal: {
      reducer: (state, action) => {
        return loop(
          { ...state, loading: true },
          Cmd.run(getJson, {
            args: [`${APP_URLS['fetchMeal']}/${action.payload.id}.json`],
            successActionCreator: meals.actions.fetchSuccessMeal,
          })
        )
      }
    },
    createMeal: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postBodyJson, {
          args: [APP_URLS['createMeal'], action.payload],
          successActionCreator: meals.actions.postSuccessMeal,
        })
      )
    },
    updateMeal: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateMeal']}/${action.payload.meal.id}`, action.payload],
          successActionCreator: meals.actions.fetchSuccessMeal,
        })
      )
    },
    fetchSuccessMeal: (state, action) => ({
      ...state,
      meals: [action.payload.meal].reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( element => element.id === currentValue.id)) {
            return accum.map( element => {
              if (element.id === currentValue.id) {
                return currentValue // keep the new value (most updated)
              } else {
                return element
              }
            })
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.meals
      ),
      loading: false,
      fetched: true,
    }),
    postSuccessMeal: (state, action) => ({
      ...state,
      meals: [...state.meals, action.payload.meal],
      loading: false,
      fetched: true,
    }),
    fetchChefGuests: state => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [APP_URLS['fetchChefGuests']],
          successActionCreator: meals.actions.fetchSuccessChefGuests,
        })
      )
    },
    fetchSuccessChefGuests: (state, action) => ({
      ...state,
      chef_guests: action.payload.chef_guests.reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( d => d.id === currentValue.id)) {
            return accum.map( element => element)
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.chef_guests
      ),
      loading: false,
      fetched: true,
    }),
    fetchChefGuest: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [`${APP_URLS['fetchChefGuest']}/${action.payload.id}.json`],
          successActionCreator: meals.actions.fetchSuccessChefGuest,
        })
      )
    },
    fetchSuccessChefGuest: (state, action) => ({
      ...state,
      chef_guests: [action.payload.chef_guest].reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( element => element.id === currentValue.id)) {
            return accum.map( element => {
              if (element.id === currentValue.id) {
                return currentValue // keep the new value (most updated)
              } else {
                return element
              }
            })
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.chef_guests
      ),
      loading: false,
      fetched: true,
    }),
    updateChefGuest: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateChefGuest']}/${action.payload.guest.id}`, action.payload],
          successActionCreator: meals.actions.fetchSuccessChefGuest,
        })
      )
    },
    fetchRecipes: state => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [APP_URLS['fetchRecipes']],
          successActionCreator: meals.actions.fetchSuccessRecipes,
        })
      )
    },
    fetchSuccessRecipes: (state, action) => ({
      ...state,
      recipes: action.payload.recipes.reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( d => d.id === currentValue.id)) {
            return accum.map( element => element ) // keep the previous value (most updated)
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.recipes
      ),
      loading: false,
      fetched: true,
    }),
    fetchRecipe: {
      reducer: (state, action) => {
        return loop(
          { ...state, loading: true },
          Cmd.run(getJson, {
            args: [`${APP_URLS['fetchRecipe']}/${action.payload.id}.json`],
            successActionCreator: meals.actions.fetchSuccessRecipe,
          })
        )
      }
    },
    fetchSuccessRecipe: (state, action) => ({
      ...state,
      recipes: [action.payload.recipe].reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( element => element.id === currentValue.id)) {
            return accum.map( element => {
              if (element.id === currentValue.id) {
                return currentValue // keep the new value (most updated)
              } else {
                return element
              }
            })
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.recipes
      ),
      loading: false,
      fetched: true,
    }),
    createRecipe: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postBodyJson, {
          args: [APP_URLS['createRecipe'], action.payload],
          successActionCreator: meals.actions.postSuccessRecipe,
        })
      )
    },
    postSuccessRecipe: (state, action) => ({
      ...state,
      recipes: [...state.recipes, action.payload.recipe],
      loading: false,
      fetched: true,
    }),
    updateRecipe: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateRecipe']}/${action.payload.recipe.id}`, action.payload],
          successActionCreator: meals.actions.fetchSuccessRecipe,
        })
      )
    },
    fetchIngredients: state => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [APP_URLS['fetchIngredients']],
          successActionCreator: meals.actions.fetchSuccessIngredient,
        })
      )
    },
    fetchSuccessIngredient: (state, action) => {
      return({
        ...state,
        ingredients: action.payload.ingredients,
        loading: false,
        fetched: true,
      })
    },
    fetchShops: state => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [APP_URLS['fetchShops']],
          successActionCreator: meals.actions.fetchShopsSuccess,
        })
      )
    },
    fetchShopsSuccess: (state, action) => {
      return({
        ...state,
        shops: action.payload.shops.reduce((previousValue, currentValue) => {
            const accum = previousValue;

            if (accum.find( d => d.id === currentValue.id)) {
              return accum.map( element => element ) // keep the previous value (most updated)
            } else {
              accum.push(currentValue)
            }

            return accum
          },
          state.shops
        ),
        loading: false,
        fetched: true,
      })
    },
    fetchShop: {
      reducer: (state, action) => {
        return loop(
          { ...state, loading: true },
          Cmd.run(getJson, {
            args: [`${APP_URLS['fetchShop']}/${action.payload.id}.json`],
            successActionCreator: meals.actions.fetchShopSuccess,
          })
        )
      }
    },
    fetchShopSuccess: (state, action) => ({
      ...state,
      shops: [action.payload.shop].reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( element => element.id === currentValue.id)) {
            return accum.map( element => {
              if (element.id === currentValue.id) {
                return currentValue // keep the new value (most updated)
              } else {
                return element
              }
            })
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.shops
      ),
      loading: false,
      fetched: true,
    }),
    updateShop: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateShop']}/${action.payload.shop.id}`, action.payload],
          successActionCreator: meals.actions.fetchShopSuccess,
        })
      )
    },
    fetchOrders: state => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [APP_URLS['fetchOrders']],
          successActionCreator: meals.actions.fetchSuccessOrders,
        })
      )
    },
    fetchSuccessOrders: (state, action) => ({
      ...state,
      orders: action.payload.orders.reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( d => d.id === currentValue.id)) {
            return accum.map( element => element ) // keep the previous value (most updated)
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.orders
      ),
      loading: false,
      fetched: true,
    }),
    fetchOrder: {
      reducer: (state, action) => {
        return loop(
          { ...state, loading: true },
          Cmd.run(getJson, {
            args: [`${APP_URLS['fetchOrder']}/${action.payload.id}.json`],
            successActionCreator: meals.actions.fetchSuccessOrder,
          })
        )
      }
    },
    createOrder: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postBodyJson, {
          args: [APP_URLS['createOrder'], action.payload],
          successActionCreator: meals.actions.postSuccessOrder,
        })
      )
    },
    postSuccessOrder: (state, action) => {
      const basket = {
        ...state.basket,
        stocks: action.payload.order.stock_items.reduce((previousValue, currentValue) => {
            const accum = previousValue;

            if (accum.find( e => e.ingredient_id === currentValue.ingredient_id) ) {
              return accum.filter( e => e.ingredient_id !== currentValue.ingredient_id)
            }

            return accum
         },
         state.basket.stocks)
      }
      setBasket(basket)
      return({
        ...state,
        basket,
        orders: [...state.orders, action.payload.order],
        loading: false,
        fetched: true,
      })
    },
    updateOrder: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateOrder']}/${action.payload.order.id}`, action.payload],
          successActionCreator: meals.actions.fetchSuccessOrder,
        })
      )
    },
    fetchSuccessOrder: (state,action) => {
      return ({
        ...state,
        orders: [action.payload.order].reduce((previousValue, currentValue) => {
            const accum = previousValue;

            if (accum.find( element => element.id === currentValue.id)) {
              return accum.map( element => {
                if (element.id === currentValue.id) {
                  return currentValue // keep the new value (most updated)
                } else {
                  return element
                }
              })
            } else {
              accum.push(currentValue)
            }

            return accum
          },
          state.orders
        ),
        loading: false,
        fetched: true,
      })
    },
    fetchStocks: state => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [APP_URLS['fetchStocks']],
          successActionCreator: meals.actions.fetchSuccessStocks,
        })
      )
    },
    createStock: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postBodyJson, {
          args: [APP_URLS['createStock'], action.payload],
          successActionCreator: meals.actions.postSuccessStock,
        })
      )
    },
    postSuccessStock: (state, action) => ({
      ...state,
      stocks: [...state.stocks, action.payload.stock],
      loading: false,
      fetched: true,
    }),
    updateStock: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateStock']}/${action.payload.stock.id}`, action.payload],
          successActionCreator: meals.actions.fetchSuccessStock,
        })
      )
    },
    fetchSuccessStock:  (state,action) => {
      return ({
        ...state,
        stocks: [action.payload.stock].reduce((previousValue, currentValue) => {
            const accum = previousValue;

            if (accum.find( element => element.id === currentValue.id)) {
              return accum.map( element => {
                if (element.id === currentValue.id) {
                  return currentValue // keep the new value (most updated)
                } else {
                  return element
                }
              })
            } else {
              accum.push(currentValue)
            }

            return accum
          },
          state.stocks
        ),
        loading: false,
        fetched: true,
      })
    },
    fetchSuccessStocks: (state, action) => ({
      ...state,
      stocks: action.payload.stocks.reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( d => d.id === currentValue.id)) {
            return accum.map( element => element ) // keep the previous value (most updated)
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.stocks
      ),
      loading: false,
      fetched: true,
    }),
    setMealFormElement: (state, action) => ({
      ...state,
      elements: {
        ...state.elements,
        mealsFormRef: {
          ...state.elements.mealsFormRef,
          ...action.payload
        }
      }
    }),
    setIngredientFormElement: (state, action) => {
      const elements = state.elements || initialState.elements;
      return({
        ...state,
        elements: {
          ...elements,
          ingredientFormRef: {
            ...elements.ingredientFormRef,
            ...action.payload
          }
        }
      })
    },
    setPermissionFormElement: (state, action) => ({
      ...state,
      elements: {
        ...state.elements,
        permissionFormRef: action.payload
      }
    }),
    setUserInfoFormElement: (state, action) => ({
      ...state,
      elements: {
        ...state.elements,
        userInfoFormRef: action.payload
      }
    }),
    setCategoryFormElement: (state, action) => ({
      ...state,
      elements: {
        ...state.elements,
        categoryFormRef: action.payload
      }
    }),
    setDietFormElement: (state, action) => ({
      ...state,
      elements: {
        ...state.elements,
        dietFormRef: action.payload
      }
    }),
    setChefGuestFormElement: (state, action) => ({
        ...state,
      elements: {
        ...state.elements,
        chefGuestFormRef: action.payload
      }
    }),
    setShopFormElement: (state, action) => ({
      ...state,
      elements: {
        ...state.elements,
        shopFormRef: action.payload
      }
    }),
    setRecipesCategoryFormElement: (state, action) => ({
      ...state,
      elements: {
        ...state.elements,
        recipesCategoryFormRef: action.payload
      }
    }),
    setScheduleFormElement: (state, action) => {
      const elements = state.elements || initialState.elements;
      return({
        ...state,
        elements: {
          ...elements,
          scheduleFormRef: {
            ...elements.scheduleFormRef,
            ...action.payload
          }
        }
      })
    },
    setChefFormElement: (state, action) => ({
        ...state,
      elements: {
        ...state.elements,
        chefFormRef: action.payload
      }
    }),
    scheduleMeals: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postBodyJson, {
          args: [APP_URLS['scheduleMeals'], action.payload],
          successActionCreator: meals.actions.scheduleMealsSuccess,
        })
      )
    },
    scheduleMealsSuccess: (state, action) => ({
      ...state,
      meals: action.payload.meals,
      loading: false,
      etched: true,
    }),
    joinRoom: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postBodyJson, {
          args: [APP_URLS['joinRoom'], action.payload],
          successActionCreator: meals.actions.joinRoomSuccess,
        })
      )
    },
    joinRoomSuccess: (state, action) => {
      const rooms = (state.user||{}).rooms||[] ;
      const newRoom = action.payload.room;
      const user = {
        ...state.user,
        rooms: [newRoom].reduce((previousValue, currentValue) => {
            const accum = previousValue;

            if (accum.find( e => e.name === newRoom.name)) { return accum }

            accum.push(currentValue)
            return accum;
          },
          rooms),
        rooms_user: action.payload.user
      }

      return({
        ...state,
        user,
        loading: false,
        fetched: true,
      })
    },
    updateHostRoomSuccess: (state, action) => {
      const rooms = (state.user||{}).rooms||[] ;
      const newRoom = action.payload.room;
      const user = {
        ...state.user,
        rooms: [newRoom].reduce((previousValue, currentValue) => {
            const accum = previousValue;

            if (accum.find( e => e.name === newRoom.name)) {
              return accum.map( e => {
                if (e.name == newRoom.name) {
                  return newRoom
                }
                return e
              })
            }

            accum.push(currentValue)
            return accum;
          },
          rooms),
        rooms_user: state.user.rooms_user
      }
      return ({
        ...state,
        user,
        loading: false,
        fetched: true,
      });
    },
    leaveRoom: (state,action) => {
      // console.log('leaveRoom', action.payload)
      return loop(
        { ...state, loading: true },
        Cmd.run(deleteBodyJson, {
          args: [APP_URLS['leaveRoom'], action.payload],
          successActionCreator: meals.actions.leaveRoomSuccess,
        })
      )
    },
    leaveRoomSuccess: (state, action) => {
      const rooms = (state.user||{}).rooms||[] ;
      const leaveRoom = action.payload.room;

      const user = {
        ...state.user,
        rooms: [leaveRoom].reduce((previousValue, currentValue) => {
            const accum = previousValue;

            if (leaveRoom.name === 'Main') { return [] }

            if (accum.find( e => e.name === leaveRoom.name)) {

              return accum.filter(e => e.name !== leaveRoom.name)
            }

            accum.push(currentValue)
            return accum;
          },
          rooms),
        rooms_user: state.user.rooms_user
      }

      return ({
        ...state,
        user,
        loading: false,
        fetched: true,
      });
    },
    recieveAppearanceStats: (state, action) => {
      const user = {
        ...state.user,
        stats: action.payload.stats
      }

      return ({
        ...state,
        user,
        loading: false,
        fetched: true,
      });
    },
    recieveNotifications: (state, action) => {
      const user = {
        ...state.user,
        contacts: action.payload.contacts ?
          action.payload.contacts :
          state.user.contacts,
        notifications: action.payload.notifications ?
          action.payload.notifications :
          state.user.notifications
      }

      return ({
        ...state,
        user,
        orders: action.payload.orders ?
                  action.payload.orders.reduce((previousValue, currentValue) => {
                      const accum = previousValue;

                      if (accum.find( d => d.id === currentValue.id)) {
                        return accum.map( element => element ) // keep the previous value (most updated)
                      } else {
                        console.log('?????')
                        accum.push(currentValue)
                      }

                      return accum
                    },
                    state.orders
                  ) : state.orders,
        loading: false,
        fetched: true,
      });
    },
    fetchMembers: {
      reducer: (state) => {
        return(
          loop(
            { ...state, loading: true },
            Cmd.run(getJson, {
              args: [APP_URLS['fetchMembers']],
              successActionCreator: meals.actions.fetchMembersSuccess,
            })
          )
        )
      }
    },
    fetchMembersSuccess: (state, action) => {
      const members = action.payload.members.reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( e => e.id === currentValue.id)) { return accum }

          accum.push(currentValue);

          return accum;
        },
        state.members)
      return({
        ...state,
        members,
        loading: false,
        fetched: true,
      })
    },
    createFriendRequest: (state,action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postJson, {
          args: [APP_URLS['createFriendRequest'](action.payload.id)],
          successActionCreator: meals.actions.createFriendRequestSuccess,
        })
      )
    },
    createFriendRequestSuccess: (state,action) => ({
      ...state,
      user: {
        ...state.user,
        ...action.payload.user
      },
      loading: false,
      fetched: true,
    }),
    openModal: (state, action) => ({
      ...state,
      modal: {
        ...state.modal,
        modalType: action.payload.modalType,
        modalActions: action.payload.modalActions,
        current: action.payload.current,
        history: action.payload.history,
        goBack: action.payload.goBack,
        open: true
      }
    }),
    closeModal: (state) => ({
      ...state,
      modal: initialState.modal
    }),
    updateFriendship: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [APP_URLS['updateFriendship'](state.user.id), action.payload],
          successActionCreator: meals.actions.updateFriendshipSuccess,
        })
      )
    },
    updateFriendshipSuccess: (state,action) => ({
      ...state,
      user: {
        ...state.user,
        ...action.payload.user
      },
      loading: false,
      fetched: true,
    }),
    updateIngredient: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateIngredient']}/${action.payload.ingredient.id}`, action.payload],
          successActionCreator: meals.actions.updateIngredientSuccess,
        })
      )
    },
    updateIngredientSuccess: (state,action) => ({
      ...state,
      ingredients: action.payload.ingredients,
      loading: false,
      fetched: true,
    }),
    fetchCategories: (state) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [APP_URLS['fetchCategories']],
          successActionCreator: meals.actions.fetchCategoriesSuccess,
        })
      )
    },
    fetchCategoriesSuccess: (state,action) => ({
      ...state,
      categories: action.payload.categories,
      loading: false,
      fetched: true,
    }),
    updateCategory: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateCategory']}/${action.payload.category.id}`, action.payload],
          successActionCreator: meals.actions.updateCategorySuccess,
        })
      )
    },
    updateCategorySuccess: state => { // check if update is correct
      return({
        ...state,
        loading: false,
        fetched: true,
      })
    },
    deleteCategory: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(deleteBodyJson, {
          args: [`${APP_URLS['deleteCategory']}/${action.payload.id}`],
          successActionCreator: meals.actions.deleteCategorySuccess,
        })
      )
    },
    deleteCategorySuccess: state => { // check if update is correct
      return({
        ...state,
        loading: false,
        fetched: true,
      })
    },
    createCategory: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postBodyJson, {
          args: [APP_URLS['createCategory'], action.payload],
          successActionCreator: meals.actions.createCategorySuccess,
        })
      )
    },
    createCategorySuccess: (state, action) => {
      return({
        ...state,
        categories: [...state.categories, action.payload.category],
        loading: false,
        fetched: true,
      })
    },
    fetchCategory: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [`${APP_URLS['fetchCategory']}/${action.payload}`],
          successActionCreator: meals.actions.fetchCategorySuccess,
        })
      )
    },
    fetchCategorySuccess: (state, action) => ({
      ...state,
      categories: [action.payload.category].reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( element => element.id === currentValue.id)) {
            return accum.map( element => {
              if (element.id === currentValue.id) {
                return currentValue // keep the new value (most updated)
              } else {
                return element
              }
            })
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.categories
      ),
      loading: false,
      fetched: true,
    }),
    fetchIngredient: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [`${APP_URLS['fetchIngredient']}/${action.payload}`],
          successActionCreator: meals.actions.fetchIngredientSuccess,
        })
      )
    },
    fetchIngredientSuccess: (state, action) => ({
      ...state,
      ingredients: state.ingredients.reduce( (previousValue, currentValue) => {
        const accum = previousValue;

        if (currentValue.id === action.payload.ingredient.id) {
          accum.push(action.payload.ingredient);
        } else {
          accum.push(currentValue);
        }
        return accum;
      },
      []),
      loading: false,
      fetched: true,
    }),
    addOrderNotificationItemToBasket: (state, action) => {
      const basket = {
        ...state.basket,
        stocks: [action.payload.stock].reduce( (previousValue, currentValue) => {
          const accum = previousValue;

          if ( accum.find( s => s.ingredient_id === currentValue.ingredient_id) ) {
            return accum
          } else {
            accum.push(currentValue);
          }
          return accum;
        },
        state.basket.stocks)
      }
      setBasket(basket)
      return({ ...state, basket})
    },
    addIngredientItemsToBasket: (state, action) => {
      const basket = {
        ...state.basket,
        stocks: action.payload.stocks.reduce( (previousValue, currentValue) => {
          const accum = previousValue;

          if ( accum.find( s => s.ingredient_id === currentValue.ingredient_id) ) {
            return accum
          } else {
            accum.push(currentValue);
          }
          return accum;
        },
        (state.basket || { stocks: []}).stocks)
      }
      setBasket(basket)
      return({ ...state, basket })
    },
    removeIngredientItemFromBasket: (state, action) => {
      const basket = {
        ...state.basket,
        stocks: [action.payload].reduce( (acc, item) => {
          return acc.slice().filter( e => e.ingredient_id !== item.ingredient_id )
        },
        state.basket.stocks)
      }
      setBasket(basket)
      return({...state, basket })
    },
    fetchDiets: (state) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [APP_URLS['fetchDiets']],
          successActionCreator: meals.actions.fetchSuccessDiets,
        })
      )
    },
    fetchSuccessDiets: (state, action) => {
      return({
        ...state,
        diets: action.payload.diets.reduce((previousValue, currentValue) => {
            const accum = previousValue;

            if (accum.find( d => d.id === currentValue.id)) {
              return accum.map( element => element ) // keep the previous value (most updated)
            } else {
              accum.push(currentValue)
            }

            return accum
          },
          state.diets
        ),
        loading: false,
        fetched: true,
      })
    },
    fetchDiet: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [`${APP_URLS['fetchDiet']}/${action.payload.id}.json`],
          successActionCreator: meals.actions.fetchSuccessDiet,
        })
      )
    },
    fetchSuccessDiet: (state, action) => {
      return({
        ...state,
        diets: [action.payload.diet].reduce((previousValue, currentValue) => {
            const accum = previousValue;

            if (accum.find( element => element.id === currentValue.id)) {
              return accum.map( element => {
                if (element.id === currentValue.id) {
                  return currentValue // keep the new value (most updated)
                } else {
                  return element
                }
              })
            } else {
              accum.push(currentValue)
            }

            return accum
          },
          state.diets
        ),
        loading: false,
        fetched: true,
      })
    },
    fetchAD: {
      reducer: (state) => {
        return(
          loop(
            { ...state, loading: true },
            Cmd.run(getJson, {
              args: [APP_URLS['fetchAD']],
              successActionCreator: meals.actions.fetchADSuccess,
            })
          )
        )
      }
    },
    fetchADSuccess: (state, action) => {
      return({
        ...state,
        user: {
          ...state.user,
          chefs: state.user.chefs.reduce((previousValue,currentValue) => {
              const accum = previousValue;
              if (currentValue.id === action.payload.id) {
                accum.push({
                  ...currentValue,
                  ad: action.payload.ad
                });
              } else {
                accum.push(currentValue);
              }
              return accum
            },
            []
          )
        },
        loading: false,
        fetched: true,
      })
    },
    fetchChef: {
      reducer: (state, action) => {
        return(
          loop(
            { ...state, loading: true },
            Cmd.run(getJson, {
              args: [`${APP_URLS['fetchChef']}/${action.payload.id}.json`],
              successActionCreator: meals.actions.fetchChefSuccess,
            })
          )
        )
      }
    },
    fetchChefSuccess: (state, action) => {
      return({
        ...state,
        user: {
          ...state.user,
          chefs: [action.payload.chef].reduce((previousValue,currentValue) => {
              const accum = previousValue;

              if (accum.find( element => element.id === currentValue.id)) {
                return accum.map( element => {
                  if (element.id === currentValue.id) {
                    return {
                      ...currentValue, // keep the new value (most updated)
                      stats: element.stats || {},
                      ad: element.ad || [],
                      ai: element.ai || [],
                      ar: element.ad || []
                    }
                  } else {
                    return element
                  }
                })
              } else {
                accum.push(currentValue)
              }

              return accum
            },
            state.user.chefs
          )
        },
        loading: false,
        fetched: true,
      })
    },
    updateChef: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateChef']}/${action.payload.chef.id}`, action.payload],
          successActionCreator: meals.actions.fetchChefSuccess,
        })
      )
    },
    updateChefBasket: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateChefBasket']}/${action.payload.chef.id}/update_basket`, action.payload],
          successActionCreator: meals.actions.fetchChefSuccess,
        })
      )
    },
    createDiet: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postBodyJson, {
          args: [APP_URLS['createDiet'], action.payload],
          successActionCreator: meals.actions.createDietSuccess,
        })
      )
    },
    createDietSuccess: (state, action) => {
      return({
        ...state,
        diets: [...state.diets, action.payload.diet],
        loading: false,
        fetched: true,
      })
    },
    updateDiet: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateDiet']}/${action.payload.diet.id}`, action.payload],
          successActionCreator: meals.actions.updateDietSuccess,
        })
      )
    },
    updateDietSuccess: state => { // check if update is correct
      return({
        ...state,
        loading: false,
        fetched: true,
      })
    },
    fetchRecipesCategories: (state) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(getJson, {
          args: [APP_URLS['fetchRecipesCategories']],
          successActionCreator: meals.actions.fetchRecipesCategoriesSuccess,
        })
      )
    },
    fetchRecipesCategoriesSuccess: (state,action) => ({
      ...state,
      recipes_categories: action.payload.recipes_categories.reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( d => d.id === currentValue.id)) {
            return accum.map( element => element ) // keep the previous value (most updated)
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.recipes_categories
      ),
      loading: false,
      fetched: true,
    }),
    fetchRecipesCategory: {
      reducer: (state, action) => {
        return loop(
          { ...state, loading: true },
          Cmd.run(getJson, {
            args: [`${APP_URLS['fetchRecipesCategory']}/${action.payload.id}.json`],
            successActionCreator: meals.actions.fetchRecipesCategorySuccess,
          })
        )
      }
    },
    fetchRecipesCategorySuccess: (state, action) => ({
      ...state,
      recipes_categories: [action.payload.recipes_category].reduce((previousValue, currentValue) => {
          const accum = previousValue;

          if (accum.find( element => element.id === currentValue.id)) {
            return accum.map( element => {
              if (element.id === currentValue.id) {
                return currentValue // keep the new value (most updated)
              } else {
                return element
              }
            })
          } else {
            accum.push(currentValue)
          }

          return accum
        },
        state.recipes_categories
      ),
      loading: false,
      fetched: true,
    }),
    updateRecipesCategory: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(putBodyJson, {
          args: [`${APP_URLS['updateRecipesCategory']}/${action.payload.recipes_category.id}`, action.payload],
          successActionCreator: meals.actions.fetchRecipesCategorySuccess,
        })
      )
    },
    createRecipesCategory: (state, action) => {
      return loop(
        { ...state, loading: true },
        Cmd.run(postBodyJson, {
          args: [`${APP_URLS['createRecipesCategory']}/${action.payload.recipes_category.id}`, action.payload],
          successActionCreator: meals.actions.fetchRecipesCategorySuccess,
        })
      )
    },
    reportsActiveDietsOpenItem: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        reports: {
          ...state.user.reports,
          active_diets: {
            ...state.user.reports.active_diets,
            open_items: [action.payload].reduce((previousValue, currentValue) => {
              const equal = (one, other) =>
                one.period === other.period &&
                one.level0 === other.level0 &&
                one.level1 === other.level1

              const accum = previousValue;

              if (accum.find( element => equal(element, currentValue) )) {
                return accum.filter( element => !equal(element, currentValue))
              } else {
                accum.push(currentValue)
              }

              return accum
            },
            state.user.reports.active_diets.open_items)
          }
        }
      }
    }),
    reportsActiveDietsAddNewMealItem: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        reports: {
          ...state.user.reports,
          active_diets: {
            ...state.user.reports.active_diets,
            open_items: [action.payload].reduce((previousValue, currentValue) => {
              const equal = (one, other) =>
                one.period === other.period &&
                one.level0 === other.level0 &&
                one.level1 === other.level1

              const accum = previousValue;

              return accum.map( element => {
                if ( equal(element, currentValue) ) {
                  return {...element, meal: currentValue.meal }
                } else {
                  return element
                }
              })
            },
            state.user.reports.active_diets.open_items)
          }
        }
      }
    }),
    reportsActiveDietsRemoveNewMealItem: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        reports: {
          ...state.user.reports,
          active_diets: {
            ...state.user.reports.active_diets,
            open_items: [action.payload].reduce((previousValue, currentValue) => {
              const equal = (one, other) =>
                one.period === other.period &&
                one.level0 === other.level0 &&
                one.level1 === other.level1

              const accum = previousValue;

              return accum.map( element => {
                if ( equal(element, currentValue) ) {
                  return {...element, meal: null }
                } else {
                  return element
                }
              })
            },
            state.user.reports.active_diets.open_items)
          }
        }
      }
    }),
    reportsRecipesOpenItem: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        reports: {
          ...state.user.reports,
          recipes: {
            ...state.user.reports.recipes,
            open_items: [action.payload].reduce((previousValue, currentValue) => {
              const equal = (one, other) =>
                one.type === other.type &&
                one.id === other.id

              const accum = previousValue;

              if (accum.find( element => equal(element, currentValue) )) {
                return accum.filter( element => !equal(element, currentValue))
              } else {
                accum.push(currentValue)
              }

              return accum
            },
            state.user.reports.recipes.open_items)
          }
        }
      }
    }),

  }
});

export default meals
