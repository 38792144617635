import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../../decorators/Lifecycle';
import CardWrapper from '../../components/Navigation/CardWrapper';

import loopProvider from '../../decorators/loopProvider';
import mealsModule from '../../redux/modules/meals';

import { ROOT_URL } from '../../shared/config';

import compact from 'lodash/compact';
import uniqBy from 'lodash/uniqBy';

import { 
  translatableWithIngredientIdName, 
  translatableName,
} from '../../shared/utils';

const I18n = window.I18n;

const MealsView = ({ meals=[], ingredients=[], actions, match, history, user, basket }) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  const meal = meals.find( e => e.id === parseInt(match.params.id) )|| { meal_dishes: [] };
  const meal_dishes = meal && meal.meal_dishes || []

  const chef = user.chefs.find( i => i.id === user.chef_id ) || {}
  const ar   = chef.ar || []
  const ai   = chef.ai || []

  const isFuturePresent = new Date <= new Date(meal.date)

  const isAvailableRecipe = (id) =>
    isFuturePresent && !!ar.find( i => i.missing === 0 && i.id === id )

  const isAvailableIngredient = (id) =>
    isFuturePresent && !!ai.find( i => i.id === id )

  const MealGuests = ({meal_dishes}) => {
    return meal_dishes.map( (e,index) => {
      return(
        <a onClick={ () => history.push(`${root}/recipes/${e.recipe.id}`) } className='item' key={`recipe_${e.id}_${index}`}>
          <span className={cn({green: isAvailableRecipe(e.recipe.id)})}>
            {translatableName(e.recipe)}
           </span>
          <span>{e.chef_guest.name}</span>
        </a>
      )
    });
  }

  const MealIngrdients = ({recipes_ingredients}) => {
    return recipes_ingredients.map( (e,index) => {
      return(
        <a onClick={ () => console.log('clicked') } className='item' key={`ingr_${e.id}_${index}`}>
          <span className={cn({green: isAvailableIngredient(e.ingredient_id)})}>
            {translatableWithIngredientIdName(e, ingredients)}
          </span>
          <span>
            {`${e.quantity} ${I18n.t(e.measure, {scope: 'quantity'})}`}
          </span>
        </a>
      )
    });
  }

  const MealBasket = ({basket_items}) =>
    // basket_items.filter( e => e.meal_id === meal.id).map( (e,index) =>
    basket_items.map( (e,index) =>
      <a onClick={ () => console.log('clicked') } className='item' key={`ingr_${e.id}_${index}`}>
        <span className={cn({green: isAvailableIngredient(e.ingredient_id)})}>
          {translatableWithIngredientIdName(e, ingredients)}
        </span>
        <span>
          {`${e.quantity} ${I18n.t(e.measure, {scope: 'quantity'})}`}
        </span>
      </a>
    )

  const recipesIngredients = () =>
    uniqBy(compact(meal_dishes.flatMap( e => e.recipe.recipe_ingredients)), 'id')

  const onAddToBasketClick = () => {
    const input = uniqBy(compact(meal_dishes.flatMap( e => e.recipe.ingredients)), 'refs.ingredient_id')
    const result = { stocks: input.map( e => ({ meal_id: meal.id, ...e.refs }) ) }

    actions.addIngredientItemsToBasket(result)
      .then( () => actions.updateChefBasket({ chef, basket }) )
  }

  const onAddOrder = () => history.push(`${root}/orders/new`)

  useEffect( () => {
    if (isFuturePresent && ar.length === 0) {
      actions.fetchAR()
    }
    if (isFuturePresent && ai.length === 0) {
      actions.fetchAI()
    }
  }, [meal])

  return (
    <Lifecycle
      onMount={()=> {
          actions.fetchMeal({ id: match.params.id }, { meta: 'whateva' })
        }}
      >
      <CardWrapper title={I18n.t('views.cards.meal')} className={'meals'} match={match} history={history}>
        <div className='fieldset'>
          <label htmlFor="meal-name">{I18n.t('views.cards.forms.meal.name')}</label>
          <span>{meal.name}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor="meal-date">{I18n.t('views.generic.date')}</label>
          <span>{meal.date}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor="meal-type">{I18n.t('views.cards.forms.meal.meal_period')}</label>
          <span>{I18n.t(meal.meal_period, { scope: 'views.cards.forms.meal.meal_periods' })}</span>
        </div>
        <div className='fieldset'>
          <label htmlFor="meal-done">{I18n.t('views.cards.forms.meal.done')}</label>
          <span>{I18n.t(Boolean(meal.done).toString(), {scope: 'views.generic.states'})}</span>
        </div>
        <h3>{I18n.t('views.cards.sections.meal.recipes_guests')}</h3>
        <MealGuests meal_dishes={meal_dishes} />
        <h3>{I18n.t('views.cards.sections.meal.meal_ingredients')}</h3>
        <MealIngrdients recipes_ingredients={recipesIngredients()}/>
        <a className='item'>
          <button onClick={onAddToBasketClick}>
            {I18n.t('views.cards.actions.add_to_backet')}
          </button>
        </a>
        <h3>{I18n.t('views.cards.sections.meal.basket')}</h3>
        <MealBasket basket_items={basket.stocks}/>
        <a className='item'>
          <button onClick={onAddOrder}>
            {I18n.t('views.cards.actions.add_order')}
          </button>
        </a>
      </CardWrapper>
    </Lifecycle>
  );
}

MealsView.propTypes = {
  ingredients: PropTypes.array,
  user: PropTypes.shape({
    chef_id: PropTypes.number,
    chefs: PropTypes.arrayOf(PropTypes.shape({
        ar: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number
          })
        ),
        ai: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number
          })
        ),
      })
    ),
    reports: PropTypes.object
  }),
  meals: PropTypes.array,
  basket: PropTypes.shape({
    orders: PropTypes.arrayOf(PropTypes.shape({
        ingredient_id: PropTypes.number,
      })
    ),
    stocks: PropTypes.array
  }),
  actions: PropTypes.shape({
    fetchMeal: PropTypes.func,
    fetchAR: PropTypes.func,
    fetchAI: PropTypes.func,
    addIngredientItemsToBasket: PropTypes.func,
    updateChefBasket: PropTypes.func,
  }),
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(MealsView);
