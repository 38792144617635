import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectModule } from 'redux-modules';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';

import Lifecycle from '../decorators/Lifecycle';
import loopProvider from '../decorators/loopProvider';
import mealsModule from '../redux/modules/meals';

import { compose } from 'recompose';

import { ROOT_URL } from '../shared/config';

import MealsList from './meals/MealsList';
import MealsNew from './meals/MealsNew';
import MealsView from './meals/MealsView';
import MealsEdit from './meals/MealsEdit';
import { getBasket, loadBasket } from '../shared/utils';

const Meals = ({actions, meals, recipes, setRouteState, user}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';


  return (
    <Lifecycle
      onMount={()=>{
        actions.fetchMeals()
        actions.fetchChefGuests()
        actions.fetchRecipes()
        actions.fetchAI()
        setRouteState()
      }}
    >
      <Switch>
        <Route path={`${root}/meals`} exact render={ routeProps =>
          <MealsList
            meals={meals}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/meals/new/:id?`} exact render={ routeProps =>
          <MealsNew
            recipes={recipes}
            meals={meals}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/meals/:id`} exact render={ routeProps =>
          <MealsView
            {...routeProps}
          />
        }/>
        <Route path={`${root}/meals/edit/:id`} render={ routeProps =>
          <MealsEdit
            recipes={recipes}
            {...routeProps}
          />
        }/>
      </Switch>
    </Lifecycle>
  )
};

Meals.propTypes = {
  setRouteState: PropTypes.func,
  actions: PropTypes.shape({
    fetchMeals: PropTypes.func,
    fetchChefGuests: PropTypes.func,
    fetchRecipes: PropTypes.func,
    fetchAI: PropTypes.func,
    addIngredientItemsToBasket: PropTypes.func,
  }),
  meals: PropTypes.array,
  recipes: PropTypes.array,
  user: PropTypes.shape({
    chefs: PropTypes.array
  })
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Meals);
