import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';

import { compose } from 'recompose';
import { connectModule } from 'redux-modules';

import Lifecycle from '../decorators/Lifecycle';
import loopProvider from '../decorators/loopProvider';
import mealsModule from '../redux/modules/meals';

import RecipesList from './recipes/RecipesList';
import RecipesNew from './recipes/RecipesNew';
import RecipesView from './recipes/RecipesView';
import RecipesEdit from './recipes/RecipesEdit';

import { ROOT_URL } from '../shared/config';

const Recipes = ({actions, recipes, ingredients, urls, user, setRouteState}) => {
  const root = ROOT_URL.length > 1 ? ROOT_URL : '';

  return(
    <Lifecycle
      onMount={()=> {
        actions.fetchRecipes();
        actions.fetchIngredients(); // stock ingredients
        actions.fetchAI();
        setRouteState()
      }}
    >
      <Switch>
        <Route path={`${root}/recipes`} exact render={ routeProps =>
          <RecipesList
            recipes={recipes}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/recipes/new/:id?`} exact render={ routeProps =>
          <RecipesNew
            user={user}
            ingredients={ingredients}
            urls={urls}
            {...routeProps}
          />
        }/>
        <Route path={`${root}/recipes/:id`} exact render={ routeProps =>
          <RecipesView
            urls={urls}
            recipe={ recipes.find(e => e.id == routeProps.match.params.id ) }
            {...routeProps}
          />
        }/>
        <Route path={`${root}/recipes/edit/:id`} render={ routeProps =>
          <RecipesEdit
            ingredients={ingredients}
            urls={urls}
            recipe={ recipes.find(e => e.id == routeProps.match.params.id ) }
            {...routeProps}
          />
        }/>
      </Switch>
    </Lifecycle>
  )
};

Recipes.propTypes = {
  setRouteState: PropTypes.func,
  urls: PropTypes.object,
  recipes: PropTypes.array,
  ingredients: PropTypes.array,
  actions: PropTypes.shape({
    fetchRecipes: PropTypes.func,
    fetchIngredients: PropTypes.func,
    fetchAI: PropTypes.func,
  }),
  user: PropTypes.object
}

export default compose(
  (Component) => loopProvider(Component),
  connectModule(mealsModule)
)(Recipes)
